import React from 'react'
import { ViewArticle } from 'routes/Articles/Detail'
import api, { getUserFromCtx } from 'shared/api'

const defaultProps = {}
const propTypes = {}

const stripTags = (original = '') => {
  return original && original.replace(/(<([^>]+)>)/gi, '')
}

export async function getServerSideProps(context) {
  const user = getUserFromCtx(context)
  const host =
    context.req.headers['x-forwarded-host'] || context.req.headers.host
  const { query } = context
  const { articleSlug } = query

  try {
    const { data } = await api.get(`/public/articles/${articleSlug}`)
    const pageProps = {
      articleSlug,
      host,
      data,
      user,
      seoConfig: {
        image: data?.image,
        title: data?.title,
        content: stripTags(data?.excerpt) || null,
      },
    }

    const { accessToken, isEmailConfirmed } = user || {}

    if (!isEmailConfirmed) {
      return {
        props: {
          ...pageProps,
          // contentData,
        },
      }
    }

    const {
      data: commentsList,
      headers: { 'x-total-results': commentCount },
    } = await api.get('/comments', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        reference: data?.id,
        page: 1,
        limit: 10,
        type: 'Article',
      },
    })

    const { data: isLiked } = await api.get(`/articles/liked/${data?.id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    return {
      props: {
        ...pageProps,
        // contentData,
        commentsData: {
          list: commentsList,
          total: commentCount,
        },
        isLiked,
      },
    }
  } catch (e) {
    return {
      props: {
        errorCode: 404,
        errorLog: e?.response?.data,
      },
    }
  }
}

export const Page = props => <ViewArticle {...props} />

Page.defaultProps = defaultProps
Page.propTypes = propTypes

export default Page
